/* eslint-disable no-unused-expressions */
import React, { useContext, useRef, useState, useEffect } from "react";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';
import { themeContext } from "../../stateProvider";
import useIntersectionObserver from "../hooks/useIntersectionObserver";


import PropTypes from "prop-types"

import './contactForm.scss';
import '../styles/fadein.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const initialState = {
  name: "",
  email: "",
  typeOfProject: "web-application",
  budget: "1000-5000",
  textMessage: ""
}

const ContactForm = (props) => {
  const { t, i18n } = useTranslation();
  const { state: themeState } = useContext(themeContext);

  const divRef = useRef();
  const { hasEntered } = useIntersectionObserver(divRef);

  const [state, setState] = useState({...initialState});

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(false);

  let successDivRef = useRef(null);
  let errorDivRef = useRef(null);

  const handleShowSuccessMessage = () => {
    successDivRef.current.style.animation = "";
    successDivRef.current.offsetWidth;
    successDivRef.current.style.animation = 'contactFormFadeInOut 5s forwards'
  }

  const handleShowErrorMessage = () => {
    errorDivRef.current.style.animation = "";
    errorDivRef.current.offsetWidth;
    errorDivRef.current.style.animation = 'contactFormFadeInOut 5s forwards'
  }

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const form = e.target
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact-form',
          ...state,
        }),
      });

      handleShowSuccessMessage();

      setState({...initialState});
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      handleShowErrorMessage();
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (state.name && state.email && state.typeOfProject && state.budget) {
      setIsSubmitAllowed(true);
    } else {
      setIsSubmitAllowed(false);
    }
  }, [state]);

  return (
    <div ref={divRef} className={`contactForm fade-in-div ${hasEntered ? 'is-visible' : ''}`}>
      <div
        className={`contactForm__successMsg`}
        ref={successDivRef}
      >
        <h3>
          {t("contactForm.success")}
        </h3>
        <div>
          {t("contactForm.replyMsg")}
        </div>
      </div>
      <div
        className={`contactForm__errorMsg`}
        ref={errorDivRef}
      >
        <h3>
          {t("contactForm.error")}
        </h3>
        <div>
          {t("contactForm.errorMsg")}
        </div>
      </div>
      <h2 className='contactForm__heading'>
        {t("contactForm.heading")}
      </h2>
      <div className='contactForm__lead'>
        {t("contactForm.lead")}
      </div>
      <form 
        name="contact-form"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/"
        onSubmit={(e) => handleSubmit(e)}
        className={`
          contactForm__form 
          contactForm__form--${themeState.theme} 
          ${isLoading ? 'contactForm__form--loading' : ''}
        `}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <label className={`contactForm__form__name__label`}>
          {t("contactForm.form.name.label")}
          <br/>
          <input
            className={`contactForm__form__name__input`}
            placeholder={t("contactForm.form.name.placeholder")}
            id="name"
            name="name"
            type="text"
            max="240"
            value={state.name}
            onChange={handleChange}
            disabled={isLoading}
          />
        </label>
        <label className={`contactForm__form__email__label`}>
          {t("contactForm.form.email.label")}
          <br/>
          <input
            className={`contactForm__form__email__input`}
            placeholder={t("contactForm.form.email.placeholder")}
            id="email"
            name="email"
            type="email"
            value={state.email}
            onChange={handleChange}
            disabled={isLoading}
          />
        </label>
        <label className={`contactForm__form__typeOfProject__label`}>
          {t("contactForm.form.typeOfProject.label")}
          <br/>
          <select
            className={`contactForm__form__typeOfProject__select`}
            id="typeOfProject"
            name="typeOfProject"
            value={state.typeOfProject}
            onChange={handleChange}
            disabled={isLoading}
          >
            <option
              value="web-application"
            >
              {t("contactForm.form.typeOfProject.web-app")}
            </option>
            <option
              value="mobile-application"
            >
              {t("contactForm.form.typeOfProject.mobile-app")}
            </option>
            <option
              value="frontend"
            >
              {t("contactForm.form.typeOfProject.frontend")}
            </option>
            <option
              value="backend"
            >
              {t("contactForm.form.typeOfProject.backend")}
            </option>
            <option
              value="content"
            >
              {t("contactForm.form.typeOfProject.content")}
            </option>
            <option
              value="internationalization"
            >
              {t("contactForm.form.typeOfProject.internationalization")}
            </option>
          </select>
        </label>
        <label className={`contactForm__form__budget__label`}>
          {t("contactForm.form.budget.label")}
          <br/>
          <select
            className={`contactForm__form__budget__select`}
            id="budget"
            name="budget"
            value={state.budget}
            onChange={handleChange}
            disabled={isLoading}
          >
            <option
              value="1000-5000"
            >
              $1,000-$5,000
            </option>
            <option
              value="5000-10000"
            >
              $5,000-$10,000
            </option>
            <option
              value="10000+"
            >
              $10,000+
            </option>
          </select>
        </label>
        <label className={`contactForm__form__description__label`}>
          {t("contactForm.form.description.label")}
          <br/>
          <textarea
            placeholder={t("contactForm.form.description.placeholder")}
            className={`contactForm__form__description__textarea`}
            data-gramm_editor="false"
            id="textMessage"
            name="textMessage"
            value={state.textMessage}
            onChange={handleChange}
            disabled={isLoading}
          />
        </label>
        <div className={`contactForm__form__submitBtn__container`}>
          {
            isLoading
            ?
            <div className="contactForm__form__submitBtn__container__spinner"><div></div><div></div><div></div><div></div></div>
            :
            null
          }
          <input
            className={`contactForm__form__submitBtn__btn`}
            type="submit"
            value={t("contactForm.form.submitBtn")}
            disabled={ 
              isSubmitAllowed 
              ? 
              (
                isLoading
                ?
                true
                :
                false 
              )
              : 
              true
            }
          />
        </div>
      </form>
      <div className='contactForm__replyMsg'>
        {t("contactForm.replyMsg")}
      </div>
    </div>
  )
}

export default ContactForm;
