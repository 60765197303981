import React from "react";
import { useTranslation } from 'react-i18next';

// Layout & SEO
import Layout from "../components/layout"
import SEO from "../components/seo"

import './about.scss'

// Components
import ContactForm from "../components/contactForm";


const AboutPage = () => {
  const { t, i18n } = useTranslation();

  return (
  <Layout>
    <SEO title="About" />
      <div className='aboutPage page'>
        <h1
          className="aboutPage__heading"
        >
          {t("aboutPage.heading")}
        </h1>
        <div className="aboutPage__body">
          <p>
            {t("aboutPage.body.p1")}
          </p>
          <p>
            {t("aboutPage.body.p2")}
          </p>
          <p>
            {t("aboutPage.body.p3")}
          </p>
          <p>
            {t("aboutPage.body.p4")}
          </p>
          <p>
            {t("aboutPage.body.p5")}
          </p>
          <p>
            {t("aboutPage.body.p6")}
          </p>
          <p>
            {t("aboutPage.body.p7")}
          </p>
        </div>
        <ContactForm />
      </div>
  </Layout>
  )
}

export default AboutPage;
